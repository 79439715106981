if (!process.env.NEXT_PUBLIC_BUILDER_PUBLIC_KEY) {
  throw new Error(
    "Missing environment variable NEXT_PUBLIC_BUILDER_PUBLIC_KEY, signup for builder.io and add your public key to env file"
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  apiKey: process.env.NEXT_PUBLIC_BUILDER_PUBLIC_KEY,
};
