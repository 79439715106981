import React from "react";
import dynamic from "next/dynamic";
import { Builder } from "@builder.io/react";

Builder.registerComponent(
  dynamic(() =>
    import("../../components/featured-items-slider/FeaturedItemsSlider")
  ),
  {
    name: "Featured items slider",
    friendlyName: "Featured items slider",
    type: "react",
    description: "Featured items slider",
    inputs: [
      {
        name: "uniqueId",
        type: "text",
        defaultValue: "listing-nearby3",
        helperText: "Unique ID for the slider",
        required: true,
      },
      {
        name: "heading",
        friendlyName: "Heading",
        type: "text",
        defaultValue: "Vakantie bestemmingen",
        required: true,
      },
      {
        name: "subHeading",
        type: "text",
        friendlyName: "Sub heading",
        defaultValue: "De mooiste bestemmingen in Nederland",
      },
      {
        name: "categoryCardType",
        friendlyName: "Category card type",
        type: "text",
        defaultValue: "card1",
        enum: ["card1", "card2", "card3"],
        helperText: "Type UI of category card",
        required: true,
      },
      {
        name: "itemPerRow",
        type: "number",
        defaultValue: 5,
        helperText: "Image of the hero section",
        required: true,
      },
    ],
    image:
      "https://tabler-icons.io/static/tabler-icons/icons-png/slideshow.png",
  }
);
