import { Text } from "@mantine/core";

const ContextModal = ({ context, id, modalBody }) => {
  return (
    <>
      <Text size="sm">{modalBody}</Text>
      <button
        onClick={() => context.closeModal(id)}
        className="w-full rounded-full bg-blue-500 px-6 py-2 text-sm text-blue-100 shadow-xl shadow-blue-400/30 transition-colors duration-300 hover:bg-blue-600"
      >
        Sluiten
      </button>
    </>
  );
};

export default ContextModal;
