import React from "react";
import dynamic from "next/dynamic";
import { Builder } from "@builder.io/react";

Builder.registerComponent(
  dynamic(() => import("../../components/section-hero/SectionHero")),
  {
    name: "Section hero",
    friendlyName: "Section hero",
    type: "react",
    description: "Hero section (used on homepage and landing pages)",
    inputs: [
      {
        name: "title",
        friendlyName: "Title",
        type: "text",
        defaultValue: "Vakantiehuizen in heel Europa",
        required: true,
      },
      {
        name: "description",
        friendlyName: "Description",
        type: "text",
        defaultValue:
          "Van een prachtige villa aan het strand tot een authentieke chalet in de bergen.",
      },
      {
        name: "buttonText",
        friendlyName: "Button text",
        type: "text",
        defaultValue: "Vakantiehuizen bekijken",
        required: true,
      },
      {
        name: "buttonLink",
        friendlyName: "Button link",
        type: "text",
        defaultValue: "/vakantiehuizen",
        required: true,
      },
      {
        name: "img",
        friendlyName: "Image URL",
        type: "text",
        defaultValue: "/assets/img/hero-right/topvakantiehuizen-hero.webp",
        helperText: "Image URL of the hero section",
        required: true,
      },
    ],
    image:
      "https://tabler-icons.io/static/tabler-icons/icons-png/brand-headlessui.png",
  }
);
