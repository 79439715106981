import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";

export const repoName = "topvakantiehuizen";
export const apiEndpoint = prismic.getRepositoryEndpoint(repoName);

/*
 * Update the Link Resolver to match your project's route structure, previews use this to find your docs
 */
export function linkResolver(doc) {
  switch (doc.type) {
    case "homepage":
      return "/";
    case "landingpage":
      return `/${doc.uid}`;
    default:
      return null;
  }
}

// This factory function allows smooth preview setup
export function createClient(config = {}) {
  const client = prismic.createClient(apiEndpoint, {
    ...config,
  });

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
}
