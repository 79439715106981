import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  startNavigationProgress,
  resetNavigationProgress,
  NavigationProgress,
} from "@mantine/nprogress";

export const RouterTransition = () => {
  const router = useRouter();

  useEffect(() => {
    // Init Mantine NProgress
    const handleStart = (url) =>
      url !== router.asPath && startNavigationProgress();
    const handleComplete = () => resetNavigationProgress();
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      // Cleanup Matine NProgress
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return <NavigationProgress size={5} color="#009ce0" />;
};
