import { useEffect } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Analytics } from "@vercel/analytics/react";
import { hotjar } from "react-hotjar";
import { PrismicProvider } from "@prismicio/react";
import { LogoJsonLd } from "next-seo";
import { PrismicPreview } from "@prismicio/next";
import { MantineProvider, NormalizeCSS } from "@mantine/core";
import { QueryParamProvider } from "use-query-params";
import { NextAdapter } from "next-query-params";
import { SessionProvider } from "next-auth/react";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { GoogleAnalytics, event } from "nextjs-google-analytics";
import dayjs from "dayjs";
import { RouterTransition } from "../components/shared/RouterTransition";
import ContextModal from "../components/modals/ContextModal";
import { linkResolver, repoName } from "../prismic";
import "styles/globals.css";

/** Chisfis CSS * */
import "styles/__theme_colors.scss";
import "styles/_header.scss";
import "styles/__theme_custom.scss";
import "styles/__theme_font.scss";
import "styles/__tw_safe_classes.scss";

/** Dayjs language for Mantine-Dates * */
import "dayjs/locale/nl";

/** Import Swiper styles * */
import "@glidejs/glide/src/assets/sass/glide.core.scss";

/** Cookie notice CSS **/
import "react-cookienotice/dist/style.css";

/** DayJS locale **/
dayjs.locale("nl");

/** Builder.io **/
import { builder } from "@builder.io/react";
import builderConfig from "../builder.config";
import "../builder/components-import";
builder.init(builderConfig.apiKey);

const ReactCookieNotice = dynamic(
  () => import("react-cookienotice").then((mod) => mod.CookieNotice),
  {
    ssr: false,
  }
);

export function reportWebVitals({ id, name, label, value }) {
  event(name, {
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  });
}

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    hotjar.initialize(
      process.env.NEXT_PUBLIC_HOTJAR_ID,
      process.env.NEXT_PUBLIC_HOTJAR_SV
    );
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <GoogleAnalytics strategy="lazyOnload" trackPageViews />
      <SessionProvider session={session}>
        <PrismicProvider
          linkResolver={linkResolver}
          internalLinkComponent={({ href, children, ...props }) => (
            <Link href={href} legacyBehavior>
              <span {...props}>{children}</span>
            </Link>
          )}
        >
          <PrismicPreview repositoryName={repoName}>
            <MantineProvider
              withGlobalStyles
              withNormalizeCSS
              theme={{
                colorScheme: "light",
                datesLocale: "nl",
              }}
            >
              <NotificationsProvider position="top-right">
                <ModalsProvider modals={{ contextModal: ContextModal }}>
                  <QueryParamProvider adapter={NextAdapter}>
                    <NormalizeCSS />
                    <RouterTransition />
                    <LogoJsonLd
                      logo="https://www.topvakantiehuizen.eu/assets/logo/TopVakantiehuizen-Logo.png"
                      url="https://www.topvakantiehuizen.eu"
                    />
                    <div className="bg-white text-base text-neutral-900 dark:bg-neutral-900 dark:text-neutral-200">
                      <Component {...pageProps} />
                    </div>
                    <div className="cookie-notice">
                      <ReactCookieNotice
                        titleLabel={
                          "We gebruiken cookies om uw gebruikservaring te verbeteren"
                        }
                        descriptionLabel={
                          'TopVakantiehuizen gebruikt cookies (en vergelijkbare technieken) om het gebruiksgemak van de website te vergroten en de website persoonlijker te maken. Door op "Accepteren" te klikken, stemt u in met het gebruik van cookies op onze website.'
                        }
                        acceptAllButtonLabel={"Accepteren"}
                        declineAllButtonLabel={"Weigeren"}
                      />
                    </div>
                    <Analytics />
                  </QueryParamProvider>
                </ModalsProvider>
              </NotificationsProvider>
            </MantineProvider>
          </PrismicPreview>
        </PrismicProvider>
      </SessionProvider>
    </>
  );
};

export default MyApp;
